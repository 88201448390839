

























import { Component, Vue } from "vue-property-decorator";
import { apiSmsGetConfig } from "@/api/marketing/sms";
@Component
export default class Sms extends Vue {
    smsData: any = [];

    getSmsList() {
        apiSmsGetConfig()
            .then((res) => {
                const data = [
                    {
                        ...res.ali,
                    },
                    {
                        ...res.tencent,
                    },
                ];
                this.smsData = data;
            })
            .catch(() => {
                this.$message.error("数据请求失败，刷新重载!");
            });
    }

    goSetting(row: any) {
        console.log(row.id);
        this.$router.push({
            path: "/sms/sms_edit",
            query: {
                id: row == 0 ? "ali" : "tencent",
            },
        });
    }

    created() {
        console.log(1);
        this.getSmsList();
    }
}
